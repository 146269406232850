import {
  createCSSPartsObjFromList,
  createCSSPartsProxy
} from '../../../../utils/cssParts/cssParts.js';

export const CSS_PARTS_LIST = [
  'base',
  'header',
  'header__title',
  'tab-group',
  'tab',
  'tab--selected',
  'tab-panel',
  'all-offers-cta',
  'offer',
  'offer--terms-open',
  'offer__bookmaker',
  'offer__bookmaker-logo',
  'offer__body',
  'offer__content',
  'offer__short-terms',
  'offer__title',
  'offer__claim-cta',
  'offer__full-terms',
  'offer__terms-cta',
  'offer__terms-cta--terms-open',
  'offer__terms-cta__label',
  'offer__terms-cta__icon',
  'powered-by',
  'footer',
  'footer__notice',
  'footer__icons'
];

export const CSS_PARTS = createCSSPartsProxy(
  createCSSPartsObjFromList(CSS_PARTS_LIST)
);
