/**
 * Fetches offers from CMS.
 *
 * @param {Object} [params={}] The url search parameters in a key-value pair data structure.
 * @param {Object} [requestOptions={}] Options object compatible to the Request interface of the Fetch API.
 * @returns {Promise} Returns a promise that resolves to the offers data or rejects if request fails for any reason.
 */
const fetchOffers = async (params = {}, requestOptions = {}) => {
  let endpoint = '';
  let req;

  if (params.slotId) {
    try {
      endpoint = process.env.CONTEXT_ENGINE_CAMPAIGNS_ENDPOINT;
    } catch {
      // Test environment fails to resolve `process`, therefore we provide a safeguard.
      endpoint = '';
    }

    const host = {
      referer: params.overrideUrl || window.location.href,
      slot: params.slotId || ''
    };

    const hostToBase64 = window.btoa(JSON.stringify(host));

    req = await fetch(`${endpoint}?json&host=${hostToBase64}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      }
    });
  } else {
    try {
      endpoint = process.env.CONTEXT_ENGINE_OFFERS_WIDGET_ENDPOINT;
    } catch {
      // Test environment fails to resolve `process`, therefore we provide a safeguard.
      endpoint = '';
    }

    const { headers, ...restOptions } = requestOptions;
    const urlParams = new URLSearchParams(params).toString();
    const url = `${endpoint}?${urlParams}`;

    req = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        ...headers
      },
      ...restOptions
    });
  }

  if (req.status === 204) {
    throw {
      name: 'Request error',
      status: req.status,
      statusText: req.statusText,
      requestText: 'No content'
    };
  }

  if (!req.ok) {
    throw {
      name: 'Request error',
      status: req.status,
      statusText: req.statusText,
      requestText: await req.text()
    };
  }

  return await req.json();
};

export const http = {
  fetchOffers
};
