/**
 * This is to safe guard against cases where, for instance, a framework may have added the element to the page and set a
 * value on one of its properties, but lazy loaded its definition. Without this guard, the upgraded element would miss that
 * property and the instance property would prevent the class property setter from ever being called.
 *
 * https://developers.google.com/web/fundamentals/web-components/best-practices#lazy-properties
 *
 * @param {HTMLElement} element The element whose properties we want to upgrade.
 * @param {String} property The property to upgrade.
 */
export const upgradeProperty = (element, property) => {
  if (Object.prototype.hasOwnProperty.call(element, property)) {
    const value = element[property];
    delete element[property];
    element[property] = value;
  }
};
