import { upgradeProperty } from '../../utils/upgradeProperty/upgradeProperty.js';
import { html } from '../../utils/lit/html.js';
import { http } from './services/http.js';
import { CSS_PARTS_LIST as DEFAULT_VARIATION_CSS_PARTS_LIST } from './variations/default/css-parts.js';
import { CSS_PARTS_LIST as GROUPED_OFFERS_VARIATION_CSS_PARTS_LIST } from './variations/grouped-offers/css-parts.js';
import { CSS_PARTS_LIST as HORIZONTAL_SLIM_VARIATION_CSS_PARTS_LIST } from './variations/horizontal-slim/css-parts.js';
import { autoAffiliate } from '../../services/auto-affiliate/auto-affiliate.js';
import { getKebabCase } from '../../utils/getKebabCase/getKebabCase.js';
import gtmIdThemeMap from './services/gtmIdThemeMap.js';

const WIDGET_VERSION = '4.8.8'; // NOTE: This value should match the last version from the widget's CHANGELOG.md file.
const WIDGET_NAME = 'oc-offers-widget';
let widgetsCloudLocation = '';

try {
  widgetsCloudLocation = process.env.WIDGETS_CLOUD_STORAGE_LOCATION;
} catch {
  // Test environment fails to resolve `process`, therefore we provide a safeguard.
  widgetsCloudLocation = '';
}

/**
 * Describes the variations that the widget supports.
 * Each variation represents a different way of rendering the widget. This includes both styles and functionality.
 * By convention, each variation should be placed in a folder named after the variation.
 * For example: The default variation is placed in the `variations/default` folder.
 *
 * Each variation is an object with the following properties:
 * - name: The name of the variation component.
 * - importScript: The function that will import the variation's script. This is used to lazy load the variation's script.
 * - cssParts: The list of CSS parts that the variation uses. This is necessary to expose the CSS parts to the variation's host.
 */
const variations = {
  default: {
    name: 'oc-offers-widget-variation-default',
    importScript: () =>
      import(
        `${widgetsCloudLocation}/${WIDGET_NAME}/variations/default/index.js`
      ),
    cssParts: DEFAULT_VARIATION_CSS_PARTS_LIST
  },
  'grouped-offers': {
    name: 'oc-offers-widget-variation-grouped-offers',
    importScript: () =>
      import(
        `${widgetsCloudLocation}/${WIDGET_NAME}/variations/grouped-offers/index.js`
      ),
    cssParts: GROUPED_OFFERS_VARIATION_CSS_PARTS_LIST
  },
  'horizontal-slim': {
    name: 'oc-offers-widget-variation-horizontal-slim',
    importScript: () =>
      import(
        `${widgetsCloudLocation}/${WIDGET_NAME}/variations/horizontal-slim/index.js`
      ),
    cssParts: HORIZONTAL_SLIM_VARIATION_CSS_PARTS_LIST
  }
};

const template = document.createElement('template');

template.innerHTML = html`
  <style>
    :host {
      box-sizing: border-box;
      display: block;
      width: 100%;
    }
  </style>

  <slot name="loading" hidden></slot>
  <slot name="error" hidden></slot>
`;

class OCOffersWidget extends HTMLElement {
  constructor() {
    super();

    if (!this.shadowRoot) {
      this.attachShadow({ mode: 'open' });
      this.shadowRoot.appendChild(template.content.cloneNode(true));
    }

    this._version = WIDGET_VERSION;
  }

  get vcId() {
    return this.getAttribute('vc-id');
  }

  set vcId(value) {
    this.setAttribute('vc-id', value);
  }

  get offerIds() {
    return this.getAttribute('offer-ids');
  }

  set offerIds(value) {
    this.setAttribute('offer-ids', value);
  }

  get slotId() {
    return this.getAttribute('slot-id');
  }

  set slotId(value) {
    this.setAttribute('slot-id', value);
  }

  get overrideUrl() {
    return this.getAttribute('override-url');
  }

  set overrideUrl(value) {
    this.setAttribute('override-url', value);
  }

  get theme() {
    return this.getAttribute('theme');
  }

  set theme(value) {
    this.setAttribute('theme', value);
  }

  get inheritFontFamily() {
    return this.hasAttribute('inherit-font-family');
  }

  set inheritFontFamily(value) {
    if (value) {
      this.setAttribute('inherit-font-family', '');
    } else {
      this.removeAttribute('inherit-font-family');
    }
  }

  get disableAnalytics() {
    return this.hasAttribute('disable-analytics');
  }

  set disableAnalytics(value) {
    if (value) {
      this.setAttribute('disable-analytics', '');
    } else {
      this.removeAttribute('disable-analytics');
    }
  }

  get geotargeting() {
    return this.hasAttribute('geotargeting');
  }

  set geotargeting(value) {
    if (value) {
      this.setAttribute('geotargeting', '');
    } else {
      this.removeAttribute('geotargeting');
    }
  }

  get alwaysShowTCs() {
    return this.hasAttribute('alwaysShowTCs');
  }

  set alwaysShowTCs(value) {
    if (value) {
      this.setAttribute('alwaysShowTCs', '');
    } else {
      this.removeAttribute('alwaysShowTCs');
    }
  }

  get placeholders() {
    return this.getAttribute('placeholders');
  }

  set placeholders(value) {
    this.setAttribute('placeholders', value);
  }

  get variation() {
    return this.getAttribute('variation');
  }

  set variation(value) {
    this.setAttribute('variation', value);
  }

  get visibleOffersCount() {
    return this.getAttribute('visible-offers-count');
  }

  set visibleOffersCount(value) {
    this.setAttribute('visible-offers-count', value);
  }

  get sortBy() {
    return this.getAttribute('sort-by');
  }

  set sortBy(value) {
    this.setAttribute('sort-by', value);
  }

  get offerLimit() {
    return this.getAttribute('offer-limit');
  }

  set offerLimit(value) {
    this.setAttribute('offerLimit', value);
  }

  static get observedAttributes() {
    return [
      'theme',
      'placeholders',
      'inherit-font-family',
      'disable-analytics',
      'style'
    ];
  }

  attributeChangedCallback(name, oldValue, newValue) {
    const variationComponent =
      this.shadowRoot.querySelector('[data-variation]');

    if (!variationComponent) {
      return;
    }

    const booleanAttrs = ['inherit-font-family', 'disable-analytics'];

    for (const attr of OCOffersWidget.observedAttributes) {
      if (name === attr && oldValue !== newValue) {
        if (booleanAttrs.includes(attr)) {
          if (newValue || newValue === '') {
            variationComponent.setAttribute(attr, '');
          } else {
            variationComponent.removeAttribute(attr);
          }
        } else {
          if (newValue) {
            variationComponent.setAttribute(attr, newValue);
          } else {
            variationComponent.removeAttribute(attr);
          }
        }
      }
    }
  }

  async connectedCallback() {
    upgradeProperty(this, 'vcId');
    upgradeProperty(this, 'offerIds');
    upgradeProperty(this, 'slotId');
    upgradeProperty(this, 'overrideUrl');
    upgradeProperty(this, 'inheritFontFamily');
    upgradeProperty(this, 'theme');
    upgradeProperty(this, 'disableAnalytics');
    upgradeProperty(this, 'geotargeting');
    upgradeProperty(this, 'placeholders');
    upgradeProperty(this, 'variation');
    upgradeProperty(this, 'visibleOffersCount');
    upgradeProperty(this, 'alwaysShowTCs');
    upgradeProperty(this, 'sortBy');
    upgradeProperty(this, 'offerLimit');

    const data = await this.#fetchData();

    if (!data || !Array.isArray(data.offers) || !data.offers.length) {
      const errorSlot = this.shadowRoot.querySelector('slot[name="error"]');
      if (errorSlot.hidden) {
        this.hidden = true;
      }

      return;
    }

    if (!data.gtmId) {
      data.gtmId = gtmIdThemeMap[this.theme];
    }

    this.#loadVariationComponent(data);

    autoAffiliate(
      this.theme,
      this.geotargeting,
      data.geolocation,
      data.bookmakersFromOffers?.map(b => b.code)
    );
  }

  setAttributesForSlotId(data, variationComponent) {
    variationComponent.setAttribute('variation', data.variation);
    variationComponent.setAttribute(
      'visible-offers-count',
      data.visibleOffersCount
    );

    if (data.theme !== '') {
      variationComponent.setAttribute('theme', data.theme);
    }

    const { inheritFontFamily, disableAnalytics, autohideTerms } = data;

    Object.entries({
      inheritFontFamily,
      disableAnalytics,
      autohideTerms
    }).forEach(([key, value]) => {
      if (value) {
        variationComponent.setAttribute(getKebabCase(key), '');
      }
    });

    const stringifiedPlaceholders = Object.entries(data.placeholders)
      .map(([key, value]) => {
        return `${key}=${value}`;
      })
      .join('|');
    variationComponent.setAttribute('placeholders', stringifiedPlaceholders);
  }

  async #loadVariationComponent(data) {
    try {
      // Set variation from this.variation or if it's using Slot ID (and not set) then take it from data.
      let variation = this.variation || data?.variation || 'default';

      if (!variations[variation]) {
        variation = 'default';
      }

      await variations[variation].importScript();

      const variationComponent = document.createElement(
        variations[variation].name
      );

      variationComponent.part = `variation-${variation}`;
      variationComponent.setAttribute('data-variation', variation);
      variationComponent.setAttribute(
        'exportparts',
        variations[variation].cssParts.join(',')
      );
      variationComponent._initialData = data;

      // Add attrs from data if it's using Slot ID
      if (this.attributes['slot-id']) {
        this.setAttributesForSlotId(data, variationComponent);
      }

      // Override attr instead, if present
      for (const attr of this.attributes) {
        variationComponent.setAttribute(attr.name, attr.value);
      }

      this.shadowRoot.appendChild(variationComponent);
    } catch (error) {
      console.error(error);
    }
  }

  async #fetchData() {
    const loadingSlot = this.shadowRoot.querySelector('slot[name="loading"]');
    const errorSlot = this.shadowRoot.querySelector('slot[name="error"]');

    loadingSlot.hidden = false;

    try {
      let data = null;

      const requestParams = {
        geotargeting: this.geotargeting
      };

      if (this.sortBy) {
        Object.assign(requestParams, { sortBy: this.sortBy });
      }

      if (this.offerLimit) {
        Object.assign(requestParams, { limit: this.offerLimit });
      }

      if (this.vcId && typeof this.vcId === 'string') {
        Object.assign(requestParams, { vcId: this.vcId });
        data = await http.fetchOffers(requestParams);
      } else if (this.offerIds && typeof this.offerIds === 'string') {
        Object.assign(requestParams, {
          offerIds: this.offerIds
            .split(',')
            .map(id => id.trim())
            .filter(Boolean)
            .join(',')
        });
        data = await http.fetchOffers(requestParams);
      } else if (this.slotId && typeof this.slotId === 'string') {
        Object.assign(requestParams, {
          slotId: this.slotId,
          overrideUrl: this.overrideUrl
        });
        data = await http.fetchOffers(requestParams);
      }

      this.dispatchEvent(
        new CustomEvent(`${WIDGET_NAME}:load-success`, {
          bubbles: true,
          composed: true,
          detail: { data }
        })
      );

      return data;
    } catch (error) {
      errorSlot.hidden = false;

      this.dispatchEvent(
        new CustomEvent(`${WIDGET_NAME}:load-error`, {
          bubbles: true,
          composed: true,
          detail: { error }
        })
      );
    } finally {
      loadingSlot.hidden = true;

      this.dispatchEvent(
        new Event(`${WIDGET_NAME}:load`, {
          bubbles: true,
          composed: true
        })
      );
    }
  }
}

if (window.customElements && !window.customElements.get(WIDGET_NAME)) {
  window.customElements.define(WIDGET_NAME, OCOffersWidget);
}
