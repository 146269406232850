import {
  createCSSPartsObjFromList,
  createCSSPartsProxy
} from '../../../../utils/cssParts/cssParts.js';

export const CSS_PARTS_LIST = [
  'base',
  'base__scroll-view',
  'nav',
  'nav__button',
  'nav__button--back',
  'nav__button--forward',
  'offer',
  'offer--terms-open',
  'offer--only-child',
  'offer__header',
  'offer__header--with-label',
  'offer__featured-label',
  'offer__featured-label-content',
  'offer__bookmaker-logo',
  'offer__body',
  'offer__content',
  'offer__short-terms',
  'offer__title',
  'offer__summary',
  'offer__claim-cta',
  'offer__full-terms',
  'offer__footer',
  'offer__terms-cta',
  'offer__terms-cta--terms-open',
  'offer__terms-cta--disabled',
  'offer__terms-cta__label',
  'offer__terms-cta__icon',
  'powered-by'
];

export const CSS_PARTS = createCSSPartsProxy(
  createCSSPartsObjFromList(CSS_PARTS_LIST)
);
