/**
 * @fileoverview
 *
 * This file contains the auto affiliation functionality.
 * The auto affiliation functionality is used to auto affiliate the user to specific bookmakers based on the widget's theme and the user's geolocation.
 *
 * The way a widget is auto affiliated depends on geotargeting. There are two cases:
 *
 * 1. `geotargeting` attribute is not set:
 *    In this case, the widget will always be auto affiliated to the available bookmakers.
 * 2. `geotargeting` attribute is set:
 *    In this case, the widget will be auto affiliated to the available bookmakers only if the user's geolocation is included in the configuration for this specific theme.
 *    In the exceptional case that the configuration for a scpecific theme is an empty array, then the widget will be auto affiliated to the available bookmakers for all geolocations.
 *    If a theme does not exist in the configuration, then the widget will not be auto affiliated at all.
 *
 * The configuration for auto affiliation functionality is defined in the `config` variable.
 */

/**
 * Configuration object for auto affiliation functionality.
 * The configuration is an object that contains the themes' names as keys and a list of the permitted geolocations as values.
 *
 * @type {Object.<string, string[]>}
 * @example
 *
 * {
 *   'yardbarker': ['us-nj', 'us-oh'],
 *   'the-33rd-team': []
 * }
 */
export const config = {
  yardbarker: ['us-on', 'us-nj', 'us-co', 'us-va', 'us-oh']
};

/**
 * List of themes that should be excluded from auto affiliation.
 *
 * @type {string[]}
 */
export const excludedThemes = ['ocus'];

/**
 * The URL of the auto affiliation library.
 * @type {string}
 */
const autoAffiliationLibraryUrl =
  'https://ads.oddschecker.com/ads/js/ads.umd.js';

/**
 * Resolves the client ID for auto affiliation depending on the configuration; widget's theme and user's geolocation.
 * The final client ID is the theme's name concatenated with the user's geolocation if the user's geolocation defined.
 *
 * @param {string} theme - The theme's name.
 * @param {boolean} isGeotargetingEnabled - Indicates whether geotargeting is enabled.
 * @param {string} geolocation - The user's location based on their IP.
 * @returns {string} The clientId for auto affiliation.
 * @example
 *
 * const config = {
 *   'yardbarker': ['us-nj', 'us-oh'],
 *   'the-33rd-team': []
 * };
 *
 * // geotargeting is disabled.
 * resolveClientId('golf-monthly', false); // => 'golf-monthly'
 *
 * // The following examples assume that geotargeting is enabled.
 *
 * // geolocation is not defined.
 * resolveClientId('golf-monthly', true); // => ''
 *
 * // "the-33rd-team" is included in the config as an empty array.
 * resolveClientId('the-33rd-team', true, 'us-oh'); // => 'the-33rd-team'
 *
 * // "golf-monthly" is not included in the config.
 * resolveClientId('golf-monthly', true, 'us-oh'); // => ''
 *
 * // The config includes the specific geolocation "us-oh".
 * resolveClientId('yardbarker', true, 'us-oh'); // => 'yardbarker-us-oh'
 *
 * // The config does not include the specific geolocation "us-va".
 * resolveClientId('yardbarker', true, 'us-va'); // => ''
 */
export function resolveClientId(theme, isGeotargetingEnabled, geolocation) {
  if (!theme || excludedThemes.includes(theme)) {
    return '';
  }

  if (isGeotargetingEnabled) {
    if (!geolocation || !Array.isArray(config[theme])) {
      return '';
    }

    if (Array.isArray(config[theme]) && config[theme].length === 0) {
      return theme;
    }

    if (Array.isArray(config[theme]) && config[theme].includes(geolocation)) {
      return `${theme}-${geolocation}`;
    }
  } else {
    return theme;
  }

  return '';
}

/**
 * Auto affiliates the user to specific bookmakers based on the theme and geolocation.
 * If for any reason the resolved client ID is empty, then the auto affiliation functionality will not be executed.
 *
 * @param {string} theme - The theme's name.
 * @param {boolean} isGeotargetingEnabled - Indicates whether geotargeting is enabled.
 * @param {string} geolocation - The user's location based on their IP.
 * @param {string[]} bookmakerCodes - List of bookmaker codes.
 */
export function autoAffiliate(
  theme,
  isGeotargetingEnabled,
  geolocation,
  bookmakerCodes
) {
  if (!Array.isArray(bookmakerCodes) || !bookmakerCodes.length) {
    return;
  }

  const clientId = resolveClientId(theme, isGeotargetingEnabled, geolocation);

  if (!clientId) {
    return;
  }

  const integrationScript = document.querySelector(
    `script[src="${autoAffiliationLibraryUrl}"]`
  );

  if (integrationScript) {
    if (typeof window.ads?.exec === 'function') {
      window.ads.exec({ operators: bookmakerCodes });
    }
  } else {
    const scriptElement = document.createElement('script');
    scriptElement.src = autoAffiliationLibraryUrl;

    scriptElement.onload = async () => {
      if (
        typeof window.ads?.init === 'function' &&
        typeof window.ads?.exec === 'function'
      ) {
        await window.ads.init({ clientId });
        window.ads.exec({ operators: bookmakerCodes });
      }
    };

    document.head.appendChild(scriptElement);
  }
}
