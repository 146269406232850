import { escapeHTML } from '../escapeHTML/escapeHTML.js';
import { sanitizeHTML } from '../sanitizeHTML/sanitizeHTML.js';

/**
 * Tag function for processing HTML template literals.
 * If the substitution is preceded by an `!`, any special characters are escaped.
 * If the substitution is preceded by an `@`, the string is sanitized.
 *
 * @param {Array} templateStrings The template strings list.
 * @param  {...any} substs One or more values that can be used to substitude parts of the template.
 * @returns {String} The processed template literal.
 */
export const html = (templateStrings, ...substs) => {
  // Use raw template strings: we don't want backslashes (\n etc.) to be interpreted.
  const raw = templateStrings.raw;
  let result = '';

  substs.forEach((subst, index) => {
    // Retrieve the template string preceding the current substitution.
    let lit = raw[index];

    // If `subst` is an Array (and not a string), we turn it into a string.
    if (Array.isArray(subst)) {
      subst = subst.join('');
    }

    // If the substitution is preceded by an `!`, we escape special characters in it.
    if (lit.endsWith('!')) {
      subst = escapeHTML(subst);
      lit = lit.slice(0, -1);
    }

    // If the substitution is preceded by an `@`, we sanitize it.
    if (lit.endsWith('@')) {
      subst = sanitizeHTML(subst);
      lit = lit.slice(0, -1);
    }

    result += lit;
    result += subst;
  });

  // Take care of last template string.
  result += raw[raw.length - 1];

  return result;
};
