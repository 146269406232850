/**
 * Converts a string to an HTML document.
 *
 * @param {String} subjectString The string to convert.
 * @returns {HTMLBodyElement} Returns an HTMLBodyElement.
 */
function stringToHTML(subjectString) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(subjectString, 'text/html');

  return doc.body || document.createElement('body');
}

/**
 * Removes <script> elements from an HTMLElement.
 *
 * @param {HTMLElement} element The HTMLElement to remove scripts from.
 */
function removeScripts(element) {
  const scripts = element.querySelectorAll('script');

  for (const script of scripts) {
    script.remove();
  }
}

/**
 * Checks if an attribute is potentially dangerous.
 *
 * @param {String} name The attribute's name.
 * @param {String} value The attribute's value.
 * @returns {Boolean} Returns true if the attribute is potentially dangerous, otherwise returns false.
 */
function isPossiblyDangerousAttribure(name, value) {
  const val = value.replace(/\s+/g, '').toLowerCase();

  if (['src', 'href', 'xlink:href'].includes(name)) {
    if (val.includes('javascript:') || val.includes('data:')) {
      return true;
    }
  }

  if (name.startsWith('on')) {
    return true;
  }

  return false;
}

/**
 * Removes potentially dangerous attributes from an element.
 *
 * @param {HTMLElement} element The element from which to remove potentially dangerous attributes.
 */
function removeAttributes(element) {
  for (const { name, value } of element.attributes) {
    if (!isPossiblyDangerousAttribure(name, value)) {
      continue;
    }

    element.removeAttribute(name);
  }
}

/**
 * Removes dangerous stuff from the HTML document's nodes reqcursively.
 *
 * @param {HTMLElement} element The HTMLElement to clean.
 */
function clean(element) {
  const nodes = element.children;

  for (const node of nodes) {
    removeAttributes(node);
    clean(node);
  }
}

/**
 * Sanitizea an HTML string.
 *
 * @param {String} subjectString The HTML string to sanitize.
 * @param {Boolean} returnNodes If true, returns HTML nodes instead of a string.
 * @returns {String|NodeList} Returns the sanitized string or its nodes.
 */
export function sanitizeHTML(subjectString, returnNodes) {
  if (typeof subjectString !== 'string') {
    subjectString = '';
  }

  const body = stringToHTML(subjectString);

  removeScripts(body);
  clean(body);

  return returnNodes ? body.childNodes : body.innerHTML;
}
