import {
  createCSSPartsObjFromList,
  createCSSPartsProxy
} from '../../../../utils/cssParts/cssParts.js';

export const CSS_PARTS_LIST = [
  'base',
  'offer',
  'offer__body',
  'offer__bookmaker-logo',
  'offer__claim-cta',
  'offer__featured-label-tag',
  'offer__terms',
  'offer__title'
];

export const CSS_PARTS = createCSSPartsProxy(
  createCSSPartsObjFromList(CSS_PARTS_LIST)
);
