/**
 * Creates a CSS parts object from a list of strings.
 *
 * @param {String[]} parts The list of CSS parts.
 * @returns {{[key: string]: string}} The CSS parts object.
 */
export const createCSSPartsObjFromList = (parts = []) => {
  return parts.reduce((accum, part) => {
    accum[part] = part;
    return accum;
  }, {});
};

/**
 * Creates a CSS parts proxy object.
 * This is used to warn the user when a CSS part is not defined.
 * It also prevents the user from setting or deleting values on the object.
 *
 * @param {{[key: string]: string}} cssPartsObj The CSS parts object.
 * @returns {Proxy} The CSS parts proxy object.
 */
export const createCSSPartsProxy = (cssPartsObj = {}) => {
  return new Proxy(cssPartsObj, {
    get(target, prop, receiver) {
      const value = target[prop];

      if (!value) {
        console.warn(`CSS part "${prop}" is not defined`);
      }

      return Reflect.get(target, prop, receiver);
    },
    set() {
      console.warn('Not allowed to set values on this object');
      return true;
    },
    deleteProperty() {
      console.warn('Not allowed to delete values on this object');
      return true;
    }
  });
};
